<template>
  <div>
    <h2>Authenticating...</h2>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { useRoute } from "vue-router";
import router from "@/router";
import store from "@/store";
import authClient from "@/api/oauth.api";
import { Role, ADMIN_ROLE_NAMES } from "@/utility/role";
import jwt from "jwt-decode";

export default {
  setup() {
    const route = useRoute();
    const authCodeGrant = ref(route.query.code ? route.query.code : null);
    const verification = ref(route.query.state ? route.query.state : null);
    const userRole = ref();
    const userGroups = ref();
    const token = ref();
    const accessToken = ref();
    const idToken = ref();
    const expiresIn = ref();

    onMounted(authenticate);

    async function authenticate() {
      if (!authCodeGrant.value) {
        router.push({
          name: "Error",
        });
        return;
      } else {
        token.value = await authClient.fetchAdminToken(authCodeGrant.value);
        if (token.value?.data?.access_token) {
          accessToken.value = token.value?.data?.access_token;
          idToken.value = token.value?.data?.id_token
            ? jwt(token.value.data.id_token)
            : null;
          expiresIn.value = token.value?.data?.expires_in;
          const accessTokenDecoded = jwt(accessToken.value);
          userGroups.value = accessTokenDecoded["cognito:groups"];
        }
      }

      if (!accessToken.value) {
        router.push({
          name: "Error",
        });
        return;
      }

      if (!idToken.value) {
        router.push({
          name: "Error",
        });
        return;
      }

      if (!verification.value) {
        router.push({
          name: "Error",
        });
        return;
      }

      if (!expiresIn.value) {
        router.push({
          name: "Error",
        });
        return;
      }

      if (!userGroups.value) {
        router.push({
          name: "Error",
        });
        return;
      } else {
        userGroups.value.forEach((group) => {
          if (group === Role.SuperAdmin) {
            userRole.value = Role.SuperAdmin;
          } else if (group === Role.InternalAdmin) {
            userRole.value = Role.InternalAdmin;
          }
        });
      }
      if (ADMIN_ROLE_NAMES.includes(userRole.value) === false) {
        router.push({
          name: "Error",
        });
        return;
      }

      store.dispatch("authenticate", {
        accessToken: accessToken.value,
        given_name: idToken.value.given_name,
        family_name: idToken.value.family_name,
        emailAddress: idToken.value.email,
        userId: idToken.value.sub,
        userRole: userRole.value,
        verification: verification.value,
      });

      if (store.getters.isAuthenticated === true) {
        window.location.href = "/internal-admin-dashboard";
      } else {
        window.location.href = "/admin-login";
      }
    }
  },
};
</script>
