<template>
  <div class="card ev-fluid-card">
    <div class="grid">
      <div class="pt-3 col-12">
        <Button label="Print" icon="pi pi-print" @click="print()"></Button>
      </div>
      <div id="invoice-content">
        <div class="col-12">
          <div class="card-header">
            <h4>Payment Confirmation</h4>
          </div>
          <div v-if="paymentsTotalAmount !== '$0.00'">
            <h3>
              {{ paymentConfirmationMessage }}
            </h3>
          </div>

          <DataTable
            :value="paymentsProcessed"
            :rows="10"
            responsiveLayout="stack"
            dataKey="invoiceId"
            class="pt-3"
          >
            <Column v-if="isCombinedInvoiceFormatEnabled === true" field="erpProjectNumber" header="Invoice">
              <template #body="slotProps">
                <span class="p-column-title">Invoice</span>
                {{ slotProps.data.erpProjectNumber + "-" + formatInvoiceNumber(slotProps.data.erpInvoiceNumber) }}
              </template>
            </Column>
            <Column v-if="isCombinedInvoiceFormatEnabled === false" field="erpProjectNumber" header="Project">
              <template #body="slotProps">
                <span class="p-column-title">erpProjectNumber</span>
                {{ slotProps.data.erpProjectNumber }}
              </template>
            </Column>
            <Column v-if="isCombinedInvoiceFormatEnabled === false" field="erpInvoiceNumber" header="Invoice">
              <template #body="slotProps">
                {{ slotProps.data.erpInvoiceNumber }}
              </template>
            </Column>
            <Column field="invoiceDate" header="Invoice Date">
              <template #body="slotProps">
                {{ formatCalendarDate(slotProps.data.invoiceDate) }}
              </template>
            </Column>
            <Column v-if="showInvoiceDueDate === true" field="dueDate" header="Due Date">
              <template #body="slotProps">
                <span v-if="slotProps.data.dueDate != null && slotProps.data.dueDate != ''">{{ formatCalendarDate(slotProps.data.dueDate) }}</span>
              </template>
            </Column>
            <Column field="paymentAmount" header="Amount">
              <template #body="slotProps">
                {{ formatToCurrency(slotProps.data.paymentAmount) }}
              </template>
            </Column>
            <Column field="paymentMethod" header="Method">
              <template #body="slotProps">
                {{ slotProps.data.paymentTypeDetail }} -
                {{ slotProps.data.last4 }}
              </template>
            </Column>
            <Column field="referenceId" header="Reference">
              <template #body="slotProps">
                {{ slotProps.data.referenceId }}
              </template>
            </Column>
            <Column field="paymentStatus" header="Status">
              <template #body="slotProps">
                {{ slotProps.data.paymentStatus }}
                <span v-if="slotProps.data.paymentStatus !== 'Payment Successful'">
                  <Button
                    v-tooltip="slotProps.data.errorMessage"
                    icon="pi pi-exclamation-circle"
                    class="p-button-rounded p-button-danger"
                  />
                </span>
              </template>
            </Column>
            <ColumnGroup type="footer">
              <Row>
                <Column
                  footer="Payment Total:"
                  :colspan="colSpan"
                  footerStyle="text-align:right"
                />
                <Column :footer="paymentsTotalAmount" />
              </Row>
            </ColumnGroup>
            <template #empty>
              <p style="text-align: center">No records found.</p>
            </template>
          </DataTable>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, ref } from "vue";
import paymentService from "@/services/payment";
import store from "@/store";
import { formatCalendarDate, formatToCurrency, formatInvoiceNumber } from "@/utility/helpers";
export default {
  setup() {
    const paymentsProcessed = computed(() => store.getters.payments);
    const paymentConfirmationMessage = computed(
      () => store.getters.paymentConfirmationMessage
    );
    const paymentsTotalAmount = ref();
    const fetchPaymentsTotalAmount = async () => {
      paymentsTotalAmount.value =
        await paymentService.fetchPaymentsProcessedTotalAmount();
    };
    const isCombinedInvoiceFormatEnabled = computed(
      () => store.getters.combinedInvoiceFormatEnabled
    );
    const colSpan = computed(() => {
      if(showInvoiceDueDate.value == true) {
        if(isCombinedInvoiceFormatEnabled.value == true) {
          return 6;
        } else {
          return 7;
        }
      } else {
        if(isCombinedInvoiceFormatEnabled.value == true) {
          return 5;
        } else {
          return 6;
        }
      }
    });
    const showInvoiceDueDate = computed(
      () => store.getters.showInvoiceDueDate
    );
    const print = async () => {
      window.print();
    };

    onMounted(fetchPaymentsTotalAmount);

    return {
      paymentsProcessed,
      paymentConfirmationMessage,
      paymentsTotalAmount,
      isCombinedInvoiceFormatEnabled,
      colSpan,
      showInvoiceDueDate,
      formatCalendarDate,
      print,
      formatToCurrency,
      formatInvoiceNumber,
    };
  },
};
</script>
